import { createZendeskTemplate } from "../utils/createZendeskTemplate"
import { parseInternalNote } from "../../../../services/parseInternalNote"
import { encryptData } from "../../../../services/encryptData"
import {
  generateUploadFiles,
  zendeskApi,
  zendeskUploadFiles,
} from "../../../../services/zendeskService"

import { sendMessage } from "../../../../services/telerivetApi"
import { medensureTextMessageTemplate } from "../../../../services/utils/smsTemplates"

const TEST_ENV = process.env.GATSBY_ENV === "production" ? "" : "[TEST] "

const createZendeskRequestTemplate = async ({
  medEnsureState,
  state,
  formFields,
}) => {
  const htmlBanner = state?.banner
  const filteredBanner = htmlBanner?.replace(/<[^>]+>/g, "")
  let tags = ["me", "request", "foundever", "has_daily_intake", "sitel"]
  const subject = `[ME Request] Request from ${medEnsureState?.email}`
  if (process.env.GATSBY_ENV !== "production") tags.push("test")

  if (
    !!medEnsureState?.prescriptionPassword &&
    medEnsureState?.prescriptionPassword !== ""
  ) {
    const encPw = await encryptData(medEnsureState?.prescriptionPassword)
    medEnsureState = { ...medEnsureState, prescriptionPassword: encPw }
  } else {
    medEnsureState = { ...medEnsureState, prescriptionPassword: "N/A" }
  }

  let newMedEnsureState = { ...medEnsureState }

  delete newMedEnsureState.documents

  return {
    request: {
      type: "request",
      subject: `${TEST_ENV}${subject}`,
      requester: {
        name: `${medEnsureState?.firstName} ${medEnsureState?.lastName}` || "",
        email: medEnsureState?.email || "it@medgrocer.com",
      },
      comment: {
        html_body: createZendeskTemplate({
          state: medEnsureState,
          formFields: formFields,
        }),
      },
      custom_fields: [
        {
          id: process.env.GATSBY_PBM_ZENDESK_STATE_CUSTOM_FIELD_ID,
          value: JSON.stringify({ ...newMedEnsureState }),
        },
        {
          id: process.env.GATSBY_PBM_ZENDESK_HOLIDAY_BANNER_CUSTOM_FIELD_ID,
          value: filteredBanner,
        },
      ],
      tags,
    },
  }
}

export const handleSubmitMedensureRequest = async ({
  medEnsureState,
  state,
  formFields,
  callback,
  errorCallback,
  files,
}) => {
  const { mobileNumber } = medEnsureState

  try {
    let pbmZendeskConfig = {
      email: process.env.GATSBY_PBM_ZENDESK_EMAIL,
      apiKey: process.env.GATSBY_PBM_ZENDESK_API_KEY,
      apiUrl: process.env.GATSBY_PBM_ZENDESK_SUBDOMAIN,
    }
    let requestTemplate = await createZendeskRequestTemplate({
      medEnsureState,
      state,
      formFields,
    })

    const generatedFileToken = medEnsureState?.documents.map(
      (document) => document.token
    )
    const comment = {
      ...requestTemplate.request.comment,
      uploads: generatedFileToken,
    }
    requestTemplate = { request: { ...requestTemplate.request, comment } }

    let response = await zendeskApi({ ...pbmZendeskConfig }).post(
      "/requests.json",
      requestTemplate
    )

    let internalNote = parseInternalNote({ state: medEnsureState })

    try {
      await zendeskApi({ ...pbmZendeskConfig }).put(
        `/tickets/${response.data.request.id}.json`,
        {
          ticket: {
            comment: {
              body: `For IT use:\n\n${JSON.stringify(internalNote)}`,
              public: false,
            },
          },
        }
      )
    } catch (error) {}

    // await sendMessage({
    //   recipient: mobileNumber,
    //   message: medensureTextMessageTemplate({
    //     zendeskID: response.data.request.id,
    //   }),
    // })
    if (callback) callback()
  } catch (error) {
    if (errorCallback) errorCallback(error)
  }
}
